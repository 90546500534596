
import { hydrateCSRTemplate } from "@fiverr-private/layout_lib/CSRTemplate";

import AppBanner from '/home/circleci/app/packages/layout-app-banner-component/src/entries/AppBanner';

const element = typeof document !== 'undefined' && document.querySelector('#AppBanner');
if (element) {
    const props = window.initialData['AppBanner'];

    hydrateCSRTemplate(AppBanner, props, element, 'AppBanner', 'AppBanner');
}
export default AppBanner;
