import { useRef } from 'react';

/**
 * Creates value exactly once.
 * `useMemo` doesn't guarantee this - https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily.
 */
const useConst = <T>(initial: () => T): T => {
    // We could also use `useState` but that's more
    // expensive internally due to reducer handling which we don't need.
    const valueRef = useRef<T | null>(null);

    if (valueRef.current === null) {
        valueRef.current = initial();
    }

    return valueRef.current;
};

export default useConst;
